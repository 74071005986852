import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8 @ 75% 1RM`}</p>
    <p>{`DB Stiff Leg Deadlift 8-8-8`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Back Squat (185/125)`}</p>
    <p>{`rest 2:00, then`}</p>
    <p>{`9-15-21 reps each of:`}</p>
    <p>{`Burpees Over Bar`}</p>
    <p>{`Front Rack Forward Lunges/Leg (95/65)`}</p>
    <p>{`For total time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`September 1st is the last day to get your teams registered for True
Grit 6 and get your shirts on the day of the competition.  Get your 4
person (2 men/2 women) team signed up today!  RX and Scaled teams will
battle it out for the 6th year at the Ville for cash prizes and other
goodies.  Competition date is September 15th. If you’d like to compete
but don’t have a team we have a signup sheet by the white board.  If
you’d like to help judge please add your name to the sheet.  We still
need several more judges.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong"><a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/true-grit-6-tickets-48356197689"
          }}>{`https://www.eventbrite.com/e/true-grit-6-tickets-48356197689`}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`For more info go to: truegritcomp.wordpress.com`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`*`}{`This Monday we will have our free Labor Day workout at 10:30am.
 Everyone is invited so bring a friend!  All other classes and open gym
are cancelled for the day.  We will reopen Tuesday at 6:00am.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      